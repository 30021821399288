
/* Home section */

  .home-view {
      width: 100%;
      margin-bottom: 0px;
  }

  .video-movil-div {
    display: none;
  }

  .mySwiper {
    max-height: 700px;
  }

  video {
    z-index: 1;
    width: 100%;
    height: 800px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:  cover;
    position: relative
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin-top: 0;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5)
    );
    width: 100%;
    padding-bottom: 100px;
  }

  /* SECTION 1*/

  #section-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  #bienvenidos-div {
    background-color: #6f00ff;
    width: fit-content;
    box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
  }

  #bienvenidos {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    padding: 10px 20px;
    color: whitesmoke;
    transition: 1s;
  }

  #la-barbieria-div {
    margin-right: 20px;
    background-color: #0e0e0e;
    width: fit-content;
    box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
  }

  #la-barbieria {
    font-size: 55px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: whitesmoke;
    padding: 10px 20px;
    transition: 1s;
  }

  #descripcion {
    font-size: 25px;
    padding: 50px 20px 20px 20px;
    transition: 1s;
  }

  /* PARALLAX */
  
  .parallax1{
    position: relative;
    width: 90%;
  }

  /* SECTION 2 */

  #section-2 {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .mySwiper2 .swiper-slide {
    transition: 0.5s all ease-in-out;
  }

  #section-2 #cuadro {
    overflow: hidden;
  }

  #section-2 img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    filter: grayscale(0);
    transition: all .3s ease-in-out;
  }

  #section-2 img:hover {
    transform: scale(1.1);
  }

  /* SECTION 3 */

  #section-3 {
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
    overflow: hidden;
    margin-top: 20px;
    display: flex;
  }

  #section-3 #inner-3-div {
    width: 90%;
    display: flex;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
    left: 52.5%;
    position: relative;
  }

  #section-3 #descripcion-3 {
    font-size: 25px;
    width: 90%;
    padding: 20px 20px 0 20px;
    transition: 1s;
  }

  #section-3 #imagen-div {
    margin-right: 100px;
  }

  .box {
    width: 350px;
    height: 350px;
    position: relative;
    animation: spin 25s ease-in-out infinite alternate-reverse;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      border: 2px dashed rgb(0, 0, 0);
      transform: scale(1.1);
    }
  }

  .box img {
    width: 350px;
    height: 350px;
    border-radius: 50%;
  }

  #imagen-movil-1 {
    display: none;
  }

  /* SECTION 4 */

  #section-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  #section-4 #inner {
    width: 90%;
    padding: 50px;
  }

  #section-4 #titulo-4-div p {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: whitesmoke;
    padding: 20px;
    width: fit-content;
    transition: 1s;
    background-color: #0e0e0e;
    box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
    transition: 1s;
  }

  #section-4 #boton-div {
    width: 100%;
  }

  #section-4 #boton-div img {
    background-color: rgba(16,46,72,0.6); 
    background-blend-mode: multiply;
  }

  #section-4 #boton-div .content {
    position: relative;
    overflow: hidden;
  }

  .content:hover .content-overlay{
    opacity: 1;
  }

  .content-image{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 200px;
    filter: brightness(60%);
  }

  .content-details {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 45%;
  }

  .content h3 {
    color: #fff;
    transition: all .8s ease-out;
    letter-spacing: 0.15em;
    will-change: transform;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
  }

  .content:hover h3 {
    -webkit-transform: scale(1.1);
    transform: scale(1.3);
    color: black;
  }

  /* TITLE TRANSITION */

  .slide-left-enter-active {
    transition: all 1s ease-out;
  }

  .slide-left-leave-active {
    transition: all 3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-left-enter-from,
  .slide-left-leave-to {
    transform: translateX(-220px);
    opacity: 0;
  }

  @keyframes morph {
    0% {border-radius: 40% 60% 80% 30% / 60% 30% 70% 40%;} 
    100% {border-radius: 30% 60% 30% 80%;} 
  }

  @keyframes spin {
    0% {
      transform: rotate(5deg);
    }
    45% {
      transform: rotate(-5deg);
    }
  }

  @media screen and (max-width: 1350px) {
    #section-1 {
      padding: 10px;
      margin-top: 10px;
    }

    #section-4 #inner{
      padding: 10px;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 1190px) {
    #section-3 #descripcion-3 {
      width: 100%;
    }

    #section-3 #imagen-div {
      display: none;
    }

    #imagen-movil-1 {
      display: block;
    }

    #imagen-movil-1 {
      background-image: url("@/assets/images/herramientas-labarbieria.jpeg");
      width: 100%;
      height: 200px;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: none;
    }
  }

  @media screen and (max-width:900px) {
    video {
      z-index: 1;
      width: 100%;
      height: 400px;
    }

    #bienvenidos {
      font-size: 26px;
    }

    #la-barbieria {
      font-size: 34px;
    }

    #descripcion {
      font-size: 18px;
    }

    #section-3 {
      padding: 10px;
      margin-top: 0;
    }

    #section-3 #descripcion-3 {
      font-size: 18px;
      width: 100%;
      padding-top: 20px;
    }

    #section-4 {
      padding: 0;
    }

    #section-4 #inner {
      padding: 10px;
      margin-bottom: 30px;
      margin-top: 10px;
      padding-right: 30px;
  }

    #section-4 #titulo-4-div {
      padding: 0px;
    }

    #section-4 p:hover {
      background-color: #6f00ff;
    }

    #section-4 #titulo-4-div p {
      font-size: 26px;
    }

    #section-4 #boton-div{
      margin-top: 10px;
    }
  }

  @media screen and (max-width:500px) {
    .video-web-div {
      display: none;
    }

    .video-movil-div {
      display: block;
    }

    .parallax1 {
      width: 90%;
    }

    #bienvenidos {
      font-size: 20px;
    }

    #la-barbieria {
      font-size: 28px;
    }

    #descripcion {
      padding: 35px 20px 0px 20px;
      font-size: 15px;
    }

    #section-3 {
      margin: 0px;
      padding: 10px;
    }

    #section-3 #descripcion-3 {
      font-size: 15px;
      padding: 5px 20px 5px 20px;
    }

    #section-4 {
      padding: 0;
    }

    #section-4 #inner {
      padding: 10px;
      padding-right: 20px;
      width: 90%;
    }

    #section-4 #titulo-4-div {
      width: 100%;
      padding: 0px;
    }

    #section-4 #titulo-4-div p {
      font-size: 20px;
    }

    #section-4 #titulo-4-div p:hover {
    background-color: #000000;
  }
    
  }

  @media screen and (max-width:400px) {
    .mySwiper {
      height: 300px;
    }
  }

  @media screen and (max-width:300px) {
    .mySwiper {
      height: 200px;
    }
  }

  @media screen and (max-width:200px) {
    .mySwiper {
      height: 100px;
    }
  }




