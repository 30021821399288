
#titulo-curso {
    box-shadow: var(--shadow2-1) 5px 5px, var(--shadow2-2) 10px 10px, var(--shadow2-3) 15px 15px, var(--shadow2-4) 20px 20px, var(--shadow2-5) 25px 25px;
}

.box-curso {
    width: 300px;
    height: 300px;
    position: relative;
    animation: spin 25s ease-in-out infinite alternate-reverse;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      border: 2px dashed #111111;
      transform: scale(1.1);
    }
}

.box-curso img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
}

.fade2-enter-active {
    transition: opacity 0.5s ease-in;
}
.fade2-leave-active {
    transition: opacity 1s ease;
}

.fade2-enter-from,
.fade2-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 1000px) {
    .box-curso {
        width: 250px;
        height: 250px;
    }

    .box-curso img {
        width: 250px;
        height: 250px;
    }
}

select {
  /*webkit browsers */
  -webkit-appearance:initial;
  /*Firefox */
  -moz-appearance:initial;
  /* modern browsers */
  appearance:initial;
  border-radius: 0;

}
