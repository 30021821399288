
    .credencial-view {
        padding-top: 110px;
        margin-bottom: 20px;
        min-height: 950px;
    }

    #section-2-credencial,
    #section-3-credencial {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #section-2-credencial {
        margin-top: 20px;
    }

    #validacion-texto-div {
        position: relative;
        width:90%;
        display: flex;
        align-items: center;
    }

    #ingresar-dni-div {
        max-width: 900px;
        margin-right: -15px;
        background-color: rgb(194, 194, 194);
        padding: 20px;
        padding-right: 40px;
        box-shadow: rgba(0, 0, 0, 0.322) 2.4px 2.4px 3.2px;
    }

    #parrafo-div {
        max-width: 600px;
        margin-right: 25px;
        margin-left: -15px;
        background-color: #0e0e0e;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    #parrafo-div p {
        color: whitesmoke;
    }

    #credencial-div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #credencial {
        position: relative;
        width:90%;
        display: flex;
        align-items: center;
 
    }

    #credencial {
        width: 400px;
        margin-top: 50px;
        margin-right: 20px;
        padding: 20px;
        background-color: #999999;
        box-shadow: rgba(0, 0, 0, 0.411) 2.4px 2.4px 3.2px;
    }
    
    #certificado p span {
        font-weight: 900;
    }

    #comercios-div {
        width:90%;
        margin-top: 50px;
    }

    #comercios-adheridos-div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: rgba(128, 128, 128, 0.219);
        max-width: 400px;
        flex-direction: column;
        padding: 20px;
    }

    #comercios-adheridos-div p {
        font-size: 19px;
        text-align: center;
    }

    #comercio-nombre-credenciales {
        font-family: 'Poppins', sans-serif;
        font-size: 25px !important;
    }

    #comercio-nombre-credenciales,
    #comercio-direccion-credenciales {
        margin-bottom: 15px;
    }

    #comercio-website-credenciales {
        margin-bottom: 10px;
        background-color: black;
        color: whitesmoke;
        padding: 10px;
        border-radius: 4px;
    }

    #redes-credenciales {
        display: flex;
        flex-wrap: wrap;
    }

    #comercios-adheridos-div img {
        width: 50px;
        cursor: pointer;
        margin: 0 5px;
    }

    #porcentaje-off-credenciales {
        margin-top: 20px;
    }

    #porcentaje-off-credenciales span {
        font-family: 'Poppins', sans-serif;
        font-size: 35px;
    }

    .btn{
        padding: 10px 17px; 
        border-radius: 3px; 
        background: #111111; 
        border: none; 
        font-size: 12px; 
        margin: 10px 0;
        color: whitesmoke;
    }

    #dni-div #floating_standard {
        width: 120px;
    }


    @media only screen and (max-width: 1350px) {
        .credencial-view #section-1 {
            padding: 40px 10px;
        }

        #section-2-credencial,
        #section-3-credencial,
        #credencial-div {
            padding: 0 10px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .credencial-view {
            padding-top: 110px;
        }

        #certificado-ejemplo {
            max-width: 700px;
        }
    }
   
    @media only screen and (max-width: 900px) {
        .credencial-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 40px 30px 0px 10px;
        }

        #validacion-texto-div {
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
        }

        #credencial-div {
            padding-top: 0;
            padding-bottom: 0;
        }

        #credencial {
            margin-top: 20px;
        }

        #parrafo-div{
            margin-right: 0px;
            margin-left: 0px;
        }

    }

    @media only screen and (max-width: 700px) {
        #titulo-certificado p {
            font-size: 5vw;
            text-transform: uppercase;
        }
    }

    @media only screen and (max-width: 600px) {
        .credencial-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 20px 30px 0px 10px;
        }

        #titulo-certificado p {
            font-size: 5vw;
            text-transform: uppercase;
        }

    }

    @media only screen and (max-width: 500px) {
        .parallax1 {
            width: 90%;
        }

        #section-1 {
            padding: 10px;
            margin-top: 10px;
        }
    }

    @media only screen and (max-width: 400px) {
        #titulo-certificado p {
            font-size: 6vw;
            text-transform: uppercase;
        }

        #dni-div input,
        #boton-verificar-div button {
            font-size: 12px;
        }

        #boton-verificar-div {
            margin-left: 0;
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 275px) {
        #credencial {
            flex-direction: column;
        }
    }

   