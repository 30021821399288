
    .certificado-view {
        padding-top: 110px;
        margin-bottom: 20px;
        min-height: 1000px;
    }

    #section-2-certificado,
    #section-3-certificado {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #boton-verificar-dni {
        background-color: black;
        color: white;
        border-radius: 4px;
        padding: 10px 20px;
        font-size: 15px;
        transition: all .4s;
    }

    #boton-verificar-dni:focus {
        outline: none;
    }

    #validacion-texto-div {
        position: relative;
        width:90%;
        display: flex;
        align-items: center;
    }

    #ingresar-dni-div {
        max-width: 900px;
        margin-right: -15px;
        background-color: rgb(194, 194, 194);
        padding: 20px;
        padding-right: 40px;
        box-shadow: rgba(0, 0, 0, 0.322) 2.4px 2.4px 3.2px;
    }

    #parrafo-div {
        max-width: 600px;
        margin-right: 25px;
        margin-left: -15px;
        background-color: #0e0e0e;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    #parrafo-div p {
        color: whitesmoke;
    }

    #certificados-div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 50px;
    }

    #certificados {
        position: relative;
        width:90%;
        display: flex;
        align-items: center;
 
    }

    #certificado {
        width: 400px;
        margin-top: 50px;
        margin-right: 20px;
        padding: 20px;
        background-color: #999999;
        box-shadow: rgba(0, 0, 0, 0.411) 2.4px 2.4px 3.2px;
    }
    
    #certificado p span {
        font-weight: 900;
    }

    #inner-certificado {
        background-color: whitesmoke;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    #certificado-imagen {
        width: 100%;
    }

    #titulo-certificado p {
        font-size: 2vw;
        text-transform: uppercase;
    }

    .btn{
        padding: 10px 17px; 
        border-radius: 3px; 
        background: #111111; 
        border: none; 
        font-size: 12px; 
        margin: 10px 0;
        color: whitesmoke;
        transition: all .4s;
    }

    #dni-div #floating_standard {
        width: 120px;
    }

    #section-3-certificado p{
        padding-bottom: 20px;
    }

    #ejemplo-div {
        display: flex;
        align-items: center;
        position: relative;
        width:90%;
        margin-top: 50px;
    }

    @media only screen and (max-width: 1350px) {
        .certificado-view #section-1 {
            padding: 40px 10px;
        }

        #section-2-certificado,
        #section-3-certificado {
            padding: 0 10px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .certificado-view {
            padding-top: 110px;
            min-height: 0;
        }

        #certificado-ejemplo {
            max-width: 700px;
        }
    }
   
    @media only screen and (max-width: 900px) {
        .certificado-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 40px 30px 0px 10px;
        }

        #validacion-texto-div {
            justify-content: flex-start;
            flex-direction: column;
        }

        #parrafo-div p,
        #inner-certificado p {
            text-align: left;
            font-size: 14px;
        }

        #ingresar-dni-div {
            margin: 0 0 -20px 0 !important;
        }

        #certificados-div {
            padding-top: 0;
            padding-bottom: 0;
        }

        #certificado {
            margin-top: 20px;
        }

        #ejemplo-div {
            margin-top: 20px;
        }

        #parrafo-div,
        #inner-certificado {
            margin-right: 0px;
            margin-left: 0px;
        }

        #titulo-certificado p {
            font-size: 30px;
        }
    }

    @media only screen and (max-width: 700px) {
        #titulo-certificado p {
            font-size: 5vw;
            text-transform: uppercase;
        }
    }

    @media only screen and (max-width: 600px) {
        .certificado-view #section-1 {
            margin: 0 auto 40px auto;
            padding: 20px 30px 0px 10px;
        }

        #titulo-certificado p {
            font-size: 5vw;
            text-transform: uppercase;
        }

    }

    @media only screen and (max-width: 400px) {
        #titulo-certificado p {
            font-size: 6vw;
            text-transform: uppercase;
        }

        #dni-div input,
        #boton-verificar-div button {
            font-size: 12px;
        }

        #boton-verificar-div {
            margin-left: 0;
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 275px) {
        #certificado {
            flex-direction: column;
        }
    }

   